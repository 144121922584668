@import url('../../theme.css');

.tableWrapper {
    overflow-x: auto;
    /* overflow-y: auto; */
}

.deleteIcon {
    color: var(--link-color-main);
}

.whitespacingTable {
    overflow-y: auto;
}

.tableWrapper table thead tr th:first-child {
    z-index: 1111 !important;
}

.actionsRow {
    min-width: 114px;
    max-width: 114px;
}

.selectableConfigSticky {
    top: 72px;    
    padding-bottom: 24px;
    box-sizing: content-box;
    margin: 0px;
}

.red {
    color: red;
}
.bold {
    font-weight: bold;
}

.opportunity {
    color: black;
}

.closed {
    color: green;
}

.oppCurrent,
.cachedInfo, 
.potentials div span:first-child {
    font-size: 14px;
}
.cachedInfo {
    margin-left: 10px;
    cursor: pointer;
    min-width: 136px;
}

.cachedInfo.inactive {
    opacity: 0.6
}

.override {
    color: #A0A4AA;
}

.strikeThrough {
    text-decoration: line-through;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.lastUpdatedWrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-bottom: 16px;
}

.filter {
    font-weight: 600;
    font-size: 14px;
    margin-left: 2px;
    display: block;
    cursor: pointer;
}

.label {
    color: var(--text-thertiary-color);
    font-weight: 500;
    font-size: 16px;
}

.filterWrapper {
    display: flex;
    flex-wrap: wrap;
}

.filterWrapper .section {
    width: 235px;
    margin-right: 30px;
    margin-bottom: 16px;
}

.sourceLabel {
    min-width: 112px;
    margin-right: 19px;
}

.comparisonRow {
    margin-bottom: 8px;
}

.simpleText {
    border-radius: 4px;
    opacity: .75;
    padding: 4px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.csvExplanations {
    font-size: 12px;
    margin-bottom: 4px;
}

.downloadButton {
    height: 20px;
}

.accountNumberWrapper {
    font-size: 18px;
    font-weight: 500;    
    color: var(--text-main-color);
}

.accountNumberWrapper div:first-child {
    color: var(--interactive-main-color);
}

.accountNumberWrapper div:nth-child(2) {
    margin-left: 5px;
}

.data {
    margin-left: 5px;
    margin-bottom: 5px;
}

.smallerTrendInput {
    max-width: 200px;
}

.trendRightPart {
    margin: 0 10px;
    overflow-y: auto;
    height: 100%;
}

.trendModalWrapper {
    height: calc(100% - 24px);
}


.fullWidth {
    width: 100% !important;
}