@import url('../../../../theme.css');

.sidebar {
    background: var(--background-color);
    width: 317px;
    height: 100%;
    border-radius: 2px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    border: 1px solid var(--border-color);
    margin-left: 20px;
}

.contactDetails {
    margin-left: 15px;
}

.departmentName {
    font-size: 18px;
    color: var(--text-main-color);
    font-weight: 500;
}

.nested {
    margin-left: 12px;
}

.nested2x {
    margin-left: 24px;
}

.parentAccountWrapper {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    align-items: center;
    margin-bottom: -10px;
}

.parentAccountWrapper a {
    margin-left: 4px;
    font-size: 12px;
    display: block;
}

.name,
.position {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 165px;
}

.name {
    color: var(--text-main-color);
    font-size: 16px;
    font-weight: 500;
}

.position {
    font-weight: normal;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.accountName {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-main-color);
}

.search {
    width: 100%;
}

.grow {
    flex-grow: 1;
    overflow-y: auto;
}

.noAccountsBlurb {
    margin-top: 16px;
    color: var(--text-main-color);
    font-size: 13.5px;
}