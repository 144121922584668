
@import url('../theme.css');

.input {
    display: block;
    width: 100%;
    height: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
    padding: 10px;
    outline: none;
}

.input.disabled {
    pointer-events: none;
}


.hasIcon .input {
    padding-left: 38px;
}

.smaller .input {
    height: 24px;
    padding-left: 25px;
}


.hasRightIcon input {
    padding-right: 38px;
}

.inputWrapper {
    position: relative;
    width: 384px;
    border: 1px solid var(--border-color);
    color: var(--text-main-color);    
    border-radius: 4px;
}

.inputWrapper.disabled {
    opacity: 0.8;
    pointer-events: none;
}

.focused {
    border-color: var(--interactive-main-color);
}

.fullWidth {
    width: 100%;
}


.inputWrapper .icon {
    position: absolute;
    margin-top: 12px;
    margin-left: 12px;
}

.inputWrapper.smaller .icon {
    margin-top: 3px;
    margin-left: 8px;
    font-size: 12px;
}

.icon {
    color: var(--text-secondary-color);
    pointer-events: none;
}

.rightIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: var(--text-secondary-color)
}

input[type=file],
input[type=file]::file-selector-button {
    cursor: pointer;
}

input[type=file]::file-selector-button {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: var(--border-color);
    color: white;
    height: 30px;
    margin-top: -9px;
    margin-left: -6px;
}