.tableElement {
    height: 100%;
}

.tableElement input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0 4px;
    background-color: transparent;
}
.tableElement.closed {
    color: green;
}

.inactive {
    background-color: white;
}
