.picker {
    position: relative;
    min-width: calc(var(--inner-size) + 2 * var(--size-step) + 1px);
    min-height: calc(var(--inner-size) + 2 * var(--size-step) + 1px);
    --inner-size: 12px;
    --size-step: 2px
}


.swatch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--inner-size) + 2 * var(--size-step) + 1px);
    height: calc(var(--inner-size) + 2 * var(--size-step) + 1px);
    background-color: transparent;
}

.partialColor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--inner-size) + 2 * var(--size-step));
    height: calc(var(--inner-size) + 2 * var(--size-step));
}

.internalColor {
    width: var(--inner-size);
    height: var(--inner-size);
}

.swatch,
.partialColor,
.internalColor {
    border-radius: 50%;
}

.swatch.disabled {
    cursor: default;
}

.popover {
    position: absolute;
    top: calc(100% + 8px);
    left: -4px;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 10000;
}

.picker.alignTowardsLeft .popover {
    left: -158px;
}

.picker.alignTowardsLeft :global(.github-picker) > :nth-child(1) {
    left: 159px !important;
    /* background: var(--background-secondary-color) !important; */
    border-color: transparent transparent var(--border-color) !important;
}

.picker.alignTowardsLeft :global(.github-picker) > :nth-child(2) {
    left: 160px !important;
    /* background: var(--background-secondary-color) !important; */
    border-color: transparent transparent var(--border-color) !important;
}