.paidOnTimeWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
}

.colorWrapper {
    border-radius: 4px;
    padding: 3px 6px;
    width: fit-content;
    font-size: 12px;
    font-weight: normal;
    height: 21px;
}