@import url('./../../theme.css');

.home {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.removeOrRearrangeDropdown {
    max-width: 145px;
}

.goalAmount {    
    font-weight: 600;
    font-size: 16px;
    color: var(--link-color-main);
}

.progressWrapper {
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 20px;
    height: 10px;
}

.progress {
    width: 80%;
    background-color: var(--link-color-main);
    border-radius: 20px;
    height: 100%;
}

.progressBottomWrapper {
    color: var(--grey-color);
    font-size: 14px;
}

.leftPart {
    min-width: 50% !important;
    max-width: 50% !important;
}

.rightPart {
    flex-grow: 1;
}

.leftPart,
.rightPart {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mainContent {
    flex-grow: 1;
}

.graph {
    height: 200px !important;
    width: 100% !important;
}

.graphs {
    display: flex;
    flex-wrap: wrap;
}

.graphs > * {
    flex-grow: 1;
    flex-basis: 40%;
    width: 45%;
}

.homeTestWrapper {
    display: flex;
    flex-basis: 1;
    flex-wrap: wrap;
}

.cardWrapper {
    height: auto;
    min-width: 650px; 
    width: 650px;
    flex-grow: 1;
}

/* .cardWrapper.smaller {
    height: 200px;
    width: 480px;
    max-width: 480px;
    min-width: 480px;
} */

.cardsWrapperShown {
    background-color: var(--background-secondary-color);
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 100;
    right: 0;
    top: 45px;
    border: 1px solid var(--border-color);
}

.doughnutWrapper {
    position: relative;
    width: 302px;
    height: 302px;
    /* margin: 0 80px; */
}

.riskWrapper {
    height: 340px;
    width: 100%;
}

.accountInfo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.numberOfAccounts,
.customGraphTotal {
    text-align: center;
    margin-top: 60px;
    /* margin-bottom: 8px; */
    font-size: 72px;
}

.customGraphTotal {
    margin-top: 92px;
    font-size: 54px;
}

.numberOfAccountsLabel {
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    font-variant: small-caps;
}


.detailsExplanation.secondRow {
    margin-top: -10px;
}
.detailsExplanation {
    margin-bottom: 8px;
    font-size: 12px; 
}

.detailsExplanation b {
    color: red;
}

.detailsExplanation.gain b {
    color: green;
}

.numberOfAccounts span {
    font-size: 30px;
}

.barGraphWrapper {
    height: 280px;
    max-height: 280px;
}

.bookOfBusiness {
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 8px;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -48px;
}

.accountsAtRiskGraphDetails span:nth-child(1) {
    min-width: 19px;
}

.accountsAtRiskGraphDetails span:nth-child(2) {
    min-width: 300px;
}

.test {
    font-size: 12px;
    line-height: 10px;
    margin-top: -2px;
    margin-bottom: 4px;
}

.accountLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accountLinksWrapper {
    margin-right: 16px;
}

.attendeeEmail {
    padding-right: 8px;
}

.graphExplanationWrapper {
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 4px;
    margin: 5px 0px;
    max-width: 290px;
    min-width: 290px;
}

.orangeIndicator {
    background: #EB862A
}

.greenishIndicator {
    background: #298072;;
}

.blueishIndicator {
    background: #ABBDD3;
}

.greenIndicator {
    background: #27C400;
}
.redIndicator{
    background: #CB3D2C;
}
.yellowIndicator {
    background: #FFC909;
}

.orangeIndicator,
.greenishIndicator,
.blueishIndicator,
.greenIndicator,
.redIndicator,
.yellowIndicator {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border-radius: 50%;
}

.explanationLabel {
    display: flex;
}

.explanationLabel .label {
    font-weight: 500;
    font-size: 16px;
}

.potentialLossWrapper .line {
    transform: scaleX(-1);
    transform: scaleY(-1);
}


.potentialLossWrapper,
.potentialGainWrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
    color: var(--text-secondary-color);
    font-size: 14px;
}

.potentialLossWrapper .line,
.potentialGainWrapper .line {
    margin-left: 30px;
    margin-right: 10px;
}

.accountsHeading {
    font-size: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.accountsHeading .count {
    color: var(--text-main-color);
    font-weight: 500;
}

.accountsHeading .accountsLabel {
    color: var(--text-secondary-color);
    margin-right: 15px;
    margin-left: 5px;
}

.homeTable {
    margin-top: 10px;
}

.salesforceReport {
    max-height: 700px;
    overflow: auto;
    min-width: calc(100% - 2 * 20px);
    width: calc(100% - 2 * 20px);
}

.salesforceReport.unlimitedHeight {
    max-height: unset;
    overflow: unset;
}