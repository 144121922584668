@import url('../../theme.css');

.wrapper {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.responses {
    margin-top: 8px;
    margin-bottom: 16px;
    width: 100%;
    height: 1px;
    flex-grow: 1;
    overflow-y: auto;
}

.askButton {
    margin-top: 4px;
    width: 100%;
}

.message {
    margin-bottom: 12px;
}

.role {
    color: var(--link-color-main);
    font-size: 14px;
}
.content {
    color: var(--text-color-main);
    font-size: 12px;
}

.bottomPart {
    min-height: 200px;
    max-height: 200px;
}

.loaderWrapper { 
    display: flex;
    align-items: center;
}