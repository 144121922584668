.nbsp {
    width: 8px;
    min-width: 8px;
    max-width: 8px;
    height: 1px;
    min-height: 100%;
}

.small {
    width: 4px;
    min-width: 4px;
    max-width: 4px;
}

.nbsp.big {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
}

.nbsp.bigger {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
}

.nbsp.extraBig {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
}