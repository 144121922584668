@import url('../../../../theme.css');

.headingWrapper {
    margin-bottom: 15px;
}

.threadsWrapper {
    background-color: var(--background-secondary-color);
    padding: 15px;
    min-width: 462px;
    max-width: 462px;
    margin-right: 30px;
}

.scrollableParent {
    overflow-y: auto;
    height: 100%;
}

.thread {
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 15px;
    min-width: 432px;
    max-width: 432px;
    min-height: 114px;
    max-height: 114px;
    border: 1px solid var(--table-header-background);
    cursor: pointer;
}
.selected {
    background-color: var(--table-header-background);;
}
.rightPart {
    width: 1px;
    flex-grow: 1;
}

.image {
    background-color: #D9D9D9;
    border-radius: 50%;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    margin-right: 15px;
    font-size: 25px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    color: #111311;
}
.tileWithDate {
    margin-bottom: 15px;
}
.title {
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.timestamp {
    font-size: 12px;
    color: var(--text-secondary-color);
    white-space: nowrap;
    margin-left: 15px;
}
.content {
    font-size: 14px;
    line-height: 16px;
    color: var(--text-secondary-color);
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.focusedThreadWrapper {
    width: 1px;
    flex-grow: 1;
}

.threadTitle {
    font-size: 22px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 15px;
}

.top {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.top > *:not(:first-child) {
    color: var(--interactive-main-color);
}

.titleRow {
    margin-bottom: 50px;
}
.senderDetails > :nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}
.senderDetails > :nth-child(2) {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-secondary-color)
}
.dropdownIndicator {
    color: var(--text-main-color);
}
.email {
    border-bottom: 1px solid var(--table-header-background);
    margin-bottom: 40px;
    padding-bottom: 10px;
}

.email:first-of-type {
    margin-top: 0;
}