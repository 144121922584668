@import url('../theme.css');

.dataTable {
    width: 100%;
    border-bottom: 1px solid var(--light-grey-color);
}

.dataTableSticky th {
    position: sticky;
    top: 0;
    z-index: 10;
}

.dataTable tr th:first-child,
.dataTable tr td:first-child{
    padding-left: 20px;
}

.dataTable tr th:last-child,
.dataTable tr td:last-child{
    padding-right: 20px;
}

.dataTable thead th {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--grey-color);
    padding: 8px 1px 8px 6px;
}

.dataTable tr td:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;    
    color: var(--text-color-main);
    width: fit-content;
}

table.dataTable > tbody > tr:not([label="extendRow"]) {
    cursor: pointer;
}


table.dataTable > tbody > tr:not([label="extendRow"]) > td:first-child::before {
    content: "▼ ";
}

table.dataTable > tbody > tr.expanded:not([label="extendRow"]) > td:first-child::before {
    content: "▲ ";
}


.dataTable tr td:not(:first-child) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color-main);
}

.dataTable tr td {
    padding-top: 18px;
    padding-bottom: 16px;
    height: 1px;
    padding-left: 6px;
}

.dataTable tr:last-child td {
    border: none;
}

.dataTable tbody tr:not(:first-child) td {
    border-top: 1px solid var(--light-grey-color); 
}


tr.dataTableExtend > td{
    padding-top: 0px;
    padding-bottom: 0px;
    border-top: 0px !important;
    padding-right: 0px !important;
}

tr.dataTableExtendHide {
    display: none;
}

.dataTableExtendInnerWrap {
    justify-content: flex-end;
    padding-left: 20px;
}

table.dataTableExtendTable {
  width: 100%;
}

table.dataTableExtendTable th {
border-bottom: 0px !important;
background-color: white;
z-index: 0;
}


table.dataTableExtendTable th:first-child {
}

table.dataTableExtendTable th:last-child {
}


table.dataTableExtendTable th,td{
    font-size: x-small;
    padding-top: 2px;
    padding-bottom: 2px;
}

table.dataTableExtendTable th{
    text-align: left !important;
}

table.dataTableExtendTable tbody tr:first-child td{
    border-top: 0px;
}

td.dataTableExtendTableTd{
    font-size: x-small !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 1px solid var(--light-grey-color) !important;
}

td.dataTableExtendTableTd:first-child {
    padding-left: 40px !important;
    text-align: left !important;
}

td.dataTableExtendTableTd:last-child {
}

.pointer {
    cursor: pointer;
}
