@import url('../../../theme.css');

.productName {
    margin-right: 12px;
}

.whitespacingTable td {
    border: 1px solid var(--link-color-main) !important;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 !important;
}

.whitespacingTable input {
    padding: 0;
    min-height: 40px;
}

.tableWrapper {
    width: 1px;
    flex-grow: 1;
    overflow-x: auto;
}

.tableWrapper table thead tr th:first-child {
    z-index: 12 !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.opportunitiesSidebar {
    min-width: 25px;
}

.whitespacingWrapper {
    height: 1px;
    flex-grow: 1;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.opportunitiesSidebar {
    height: 100%;
    position: relative;
    color: var(--link-color-main)
}

.opportunitiesSidebarShown {
    min-width: 280px;
    height: 100%;
    overflow-y: auto;
}

.sidebarBorder {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: 1px solid var(--link-color-main);
}

.sidebarBorder svg {
    position: absolute;
    top: 50%;
    left: -10.5px;
}

.closedOpportunitiesFilterWrapper {
    padding-right: 8px;
    margin: 8px 0;
}

.productsSidebar, .productsSidebarWrapper {
    flex-direction: column;
}

.productsSidebarWrapper {
    height: calc(100% - 66px);
    width: calc(100% - 16px);
}

.pointer {
    cursor: pointer;
}