@import url('./reset.css');
@import url('./theme.css');

@font-face {
  font-family: 'Sora';
  src: local('sora-variablefont_wght-webfont'), url(./sora-variablefont_wght-webfont.woff2) format('woff2'),
       local('sora-variablefont_wght-webfont'), url(./sora-variablefont_wght-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0 !important;
  padding: 0 !important;
  line-height: inherit;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
  font-family: "Sora", sans-serif;
  color: var(--text-main-color);
}

#root * {
  outline: none;

  transition-property: background, background-color, border-color, color;
  transition-duration: 333ms;
}

.clip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.red {
  color: #CB3D2C !important;
}
.green {
  color: #A0FFBB !important;
}
.font-size-smaller {
  font-size: 12px;
}

.reactTooltipWrapper {
  z-index: 99999999999 !important;
}

#root.downscaled {
  zoom: 0.55; 
  -moz-transform: scale(0.55); 
  -moz-transform-origin: 0 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  appearance: none;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

#root {
  background-color: #151916;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__day {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  color: var(--text-main-color) !important;
  margin: 9px !important;
}

.react-datepicker__day:hover {
  background-color: var(--interactive-main-color) !important;
  color: #111311 !important;
}

.react-datepicker__day--selected {
  border-radius: 50% !important;
  color: #111311 !important;
  background: var(--interactive-main-color) !important;
  outline: none;
}

.react-datepicker__header {
  background: var(--background-secondary-color) !important;
  border: none !important;
  padding-top: 18px !important
}

.react-datepicker__month,
.react-datepicker {
  background: var(--background-secondary-color) !important;
}

.react-datepicker__current-month {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 25px !important;

  text-align: center !important;

  color: var(--interactive-main-color) !important;
  margin-bottom: 10px;
}

.react-datepicker__day-name {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  letter-spacing: -0.41px !important;
  text-transform: uppercase !important;

  margin: 0 9px !important;
  color: var(--text-main-color) !important;
}

.react-datepicker__navigation.react-datepicker__navigation--previous,
.react-datepicker__navigation.react-datepicker__navigation--next {
  margin-top: 12px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.github-picker {
  width: 212px !important;
  background: var(--background-secondary-color) !important;
  border: 1px solid var(--border-color) !important;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  width: 6px;
  background-color: #F6F6F6;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: var( --interactive-main-color);
  width: 6px;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar:horizontal {
  height: 8px;
}

#impersonationOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 3px);
    height: calc(100% - 2px);
    border: 3px solid red;
    pointer-events: none;
}

.react-contexify {
  box-shadow: 0px 0px 1px 1px var(--interactive-main-color-50) !important;
  background-color: var(--table-dropdown-background) !important;
  z-index: 10000 !important;
}

.react-contexify__item__content {
  color: var(--text-main-color) !important;
}

.react-contexify__separator {
  background-color: var(--border-color) !important;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
.react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  background-color: var(--interactive-main-color-10) !important;
}

.sketch-picker {
  width: calc(100% - 2 * 10px) !important;
  background-color: transparent !important;
  color: var(--text-main-color) !important;
  border-radius: 2px !important;
}

.sketch-picker label {
  color: var(--text-main-color) !important;
}