.subHeaderWrapper {
    margin-bottom: 8px;
    margin-top: 16px;
}

.newTypeDropdown {
    height: 48px;
}

.stage {
    user-select: none;
    width: 100%;
    height: 52px;
    background: #FFFFFF;        
    width: 100%;
    border-bottom: 1px solid #E0E0E4;
}

.columnContainer {
    width: 100%;
    min-height: 100%;
}

.stageRow {
    width: 100%;
    padding-left: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--link-color-main);
}

.stageRow.header {
    color: #A0A4AA;
    background-color: #F6F6F6;
    height: 48px;
    border-radius: 4px;  
    border-bottom:  none;  
}


.stageRow > :nth-child(1) {
    width: 200px;
}
.stageRow > :nth-child(2),
.stageRow > :nth-child(3),
.stageRow > :nth-child(4),
.stageRow > :nth-child(5) {
    width: 150px;
}

.stageRow > :nth-child(5) {
    max-width: 90px;
}

.stageRow > :nth-child(1),
.stageRow > :nth-child(2),
.stageRow > :nth-child(3),
.stageRow > :nth-child(4),
.stageRow > :nth-child(5) {
    flex-grow: 1;
}