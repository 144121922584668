@import url('../../../theme.css');

.draggableField {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: var(--background-color);
    margin: 5px 0;
}


.draggingOver {
    background-color: var(--interactive-main-color-50);
}

.draggableField {
    padding: 0 10px;
}