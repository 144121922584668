@import url('../theme.css');

.checkbox {
    position: relative;
}

.checkbox.labelColor {
    color: var(--text-main-color);
}

.checkbox input {
    appearance: none;
    background-color: transparent;
    border: 2px solid var(--interactive-main-color);
    border-radius: 2px;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    outline: none !important;
    box-shadow: none !important;
    -webkit-transition: all .2s;
            transition: all .2s;
}

.checkbox.disabled input {
    opacity: 0.6;
}

.checkbox.disabled input:checked {
    background-color: var(--text-secondary-color);
}

.checkbox input:checked {
    border-color: var(--interactive-main-color);
}

.checkbox svg { 
    position: absolute;
    pointer-events: none;
    left: -0.5px;
    top: 0.5px;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox.alignTop {
    align-items: start;
}

.hasLabel label,
.checkboxWrapper {
    padding-top: 2px;
}

.hasLabel label { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hasLabel {
    display: flex;
    justify-content: space-between;
    color: var(--text-main-color);
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    height: 44px;
    padding: 0 10px;
    margin-bottom: 10px;
    width: 100%;
}

.hasLabel.checked {
    background-color: var(--interactive-main-color-10);
    border: 1px solid var(--interactive-main-color-50);
    border-radius: 4px;
}

.checkboxWrapper {
    position: relative;
}