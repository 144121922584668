.horizontalScroll {
    overflow-x: auto;
}

.groupsTable thead th {
    overflow: unset !important;
    text-overflow: unset !important;
    word-break: break-all;
}

.groupsTable thead th > div {
    padding: 0 6px;
    word-break: break-all;
}

.groupsTable thead th > div > div {
    overflow: unset;
    word-break: break-all;
}

.groupsTable thead tr > :last-child {
    text-align: right;
}

.groupsTable tbody tr > td:last-child {
    text-align: right;
}

