.drillDownModalWrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    min-width: 400px;
}

.drillDownModalWrap div {
    display: flex;
    align-items: flex-start;
}


.drillDownModalTabs {
    flex-direction: row;
    justify-content: flex-start;
}

.drillDownModalTabs div {
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    width: fit-content;
    text-transform: uppercase;
    margin-right: 8px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px 4px 0px 0px;
    justify-content: center;
    cursor: pointer;
}

.drillDownModalTabNorm {
    background-color: var(--grey-color);
    color: white;
    font-weight: 500;
}


.drillDownModalTabSelect {
    color: white;
    background-color: var(--link-color-main);
}

.drillDownModalMain {
    border: 1px solid var(--grey-color);
    width: fit-content;
    min-width: 100%;
    padding: 8px 16px 8px 16px;
    flex-grow: 10;
    justify-content: flex-start;
}

.drillDownModalMainWrap {
    height: fit-content;
    width: 100%;
    justify-content: center;
}

.drillDownPointer {
    cursor: pointer;
}
