.dataTableSearch {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dataTableSearch.skipMargin {
    margin-bottom: 0;
}
