@import url('../../theme.css');

.accountWrapper {
    display: flex;
    cursor: pointer;
    position: relative;
}

.accountName {
    color: var(--text-color-main);
    text-decoration: none;
    text-decoration-color: transparent;
    border-bottom: none;
}

.userNameFilter {
    width: fit-content;
    margin-right: 16px;
}

.opportunities {
    display: flex;
    justify-content: flex-end;
}

.opportunities > * {
    cursor: pointer;
    padding: 0 8px;
    color: var(--link-color-main);
}

.modalLoader {
    margin: 20px;
}

.accountInputsWrapper > * {
    margin-bottom: 12px;
}

.addButton {
    margin-right: 12px;
}

.accountReassignWrapper {
    min-height: 200px;
    height: 200px;
}

.opportunityActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.colModalWrapper {
    min-height: 460px;
}

.colModalWrapperSelect {
    margin-bottom: 16px;
}

.tableHeaderAction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tableHeaderActionLabel {
    overflow: hidden;
    text-overflow: ellipsis;
}

.colNotice {
    padding: 16px;
    margin-bottom: 16px;
    color: var(--warning-orange-text-color);
    background-color: var(--warning-yellow-background-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: pre-wrap;
}

.colNotice div:first-child {
    margin-right: 12px;
}

.tableRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.tableFilterDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin-right: 16px;
}

.searchInput {
    margin-bottom: 0px;
    margin-left: 16px;
}

.loader {
    margin: 0px;
}

.tableMinHeight {
    min-height: 757px;
}

.modalLoader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.tableCellOverflowHide {
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 48px;
    cursor: text; 
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
