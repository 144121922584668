.sfdcProductsWrapper {
    width: 1px;
    flex-grow: 1;
    padding-right: 20px;
}

.sfdcProductFieldLabel {
    width: 100%;
    text-align: left;
}

.sfdcProductRow > :first-child {
    min-width: 120px;
    max-width: 180px;
}

.sfdcOppFieldLabel {
    min-width: 150px;
    max-width: 150px;
}

.subHeader {
    width: calc(100% - 5px);
}