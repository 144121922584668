.alignEnd {
    align-items: end;
}

.green {
    background-color: #3EB984;
}

.red {
    background-color: #E10000;
}

.yellow {
    background-color: #F8D261;
}

.colorEvaluationIndicator {
    min-width: 16px;
    max-width: 16px;
    height: 8px;
    border-radius: 4px;
}

.fullWidth {
    width: 100%;
}

.deleteButton {
    margin-bottom: 3px;
}

.marginBottom {
    margin-bottom: 8px;
}

.smallerMarginBottom {
    /* margin-bottom: 4px; */
}

.overrideLabel {
    min-width: 110px;
    padding-top: 22px;
}

.groupTreshhold {
    width: 80px;
    margin: 0 5px;
}

.groupTreshholdLabel1 {
    width: 318px;
}

.groupList {
    white-space: nowrap;
    width: calc(100% - 45px);
}