@import url('../../../../theme.css');

.value {
    display: flex;
    align-items: center;
}

.secondColumn .value {
    text-align: right;
    flex-grow: 1;
}

.detailsRow {

}

.value {
    color: var(--text-main-color);
    font-size: 16px;
    font-weight: 500;
}