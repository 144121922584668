.statsSummaryRow {
    display: flex;
    align-items: center;
}

.percentage {
    font-size: 14px;
    line-height: 16px;
    font-variant: small-caps;
}

.percentage.red {
    color: #E10000;
}

.percentage.green {
    color: #3EB984;
}

.percentage span {
    margin-right: 6px;
}

.clickable {
    cursor: pointer;
}

.oldLabel {
    margin-left: 6px;
    color: #A0A4AA;
    font-size: 10px;
    line-height: 12px;
    font-variant: small-caps;
    text-transform: uppercase;
}