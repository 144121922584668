@import url('../../theme.css');

.smaller {
    width: 30%;
}

.smaller > div {
    display: flex;
}

.bigger {
    width: 70%;
    display: flex;
    min-width: 1050px;
    flex-direction: column;
}

.bigger > * {
    flex-grow: 1;
}

.smaller, .bigger {
    color: var(--text-main-color);
    flex-grow: 1;
    height: unset;
}

.moneyStatisticsContainer {
    display: flex;  
    justify-content: space-between;
    flex-wrap: wrap;
}

.moneyStatisticsContainer.firstRow {
    margin-bottom: 28px;
    margin-top: 18px;
}

.moneyStatisticsContainer > * {
    flex-grow: 1;
}

.moneyStatisticsContainer.firstRow > *:nth-child(1) {
    margin: 0;
}

.moneyStatisticsContainer > * {
    margin-left: 60px;
}

.doughnutWrapper {
    position: relative;
    width: 308px;
    height: 308px;
    margin-top: -18px;
    margin-left: 76px;
}

.accountInfo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.numberOfAccounts {
    text-align: center;
    margin-top: 60px;
    /* margin-bottom: 8px; */
    font-size: 72px;
}

.numberOfAccountsLabel {
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    font-variant: small-caps;
}

.accountsExplanation span:first-child {
    color: var(--background-secondary-color);
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    margin-right: 16px;
}

.accountsExplanation span:last-child {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.numberOfAccountsChurnedLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.numberOfAccounts,
.numberOfAccountsChurnedStat {
    font-weight: 500;
}

.numberOfAccountsChurnedStat {
    font-size: 36px;
    line-height: 42px;
    margin-left: 16px;
    margin-top: -10px;
}

.accountsChurnedSummary {
    margin-left: 24px;
    margin-top: 16px;
}

.accountsChurnedSummary > *:first-child {
    margin-bottom: 22px;
}

.winRateGraphWrapper {
    width: 400px;
    height: auto;
}

.oppsLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 24px;
}

.oppsRateGraphWrapper {
    width: 400px;
    height: 200px;
}

.oppsOpenRateGraphWrapper {
    width: 300px;
    height: 200px;
}

.oppsDataCol:first-child {
    margin-left: 20px;
    margin-right: 60px;
}

.oppsDataCol:nth-child(2) {
    margin-right: 60px;
}

.oppsOpenRateGraphWrapper {
    margin-top: -40px;
}

.oppsDataColWrapper {
    justify-content: space-between;
}

.dropdownStats {
    height: 135px;
}

.accountPlanDropdownMargin {
    margin-top: 32px;
}

.selectableConfig {
    margin-left: 12px;
}

.selectableConfig > *:nth-child(2) {
    margin: 0 24px;
}

.selectableConfigSticky {
    top: 72px;
    position: sticky;
    z-index: 999;
    background-color: var(--background-secondary-color);
    padding: 24px;
    box-sizing: content-box;
    margin: 0px;
}

.loader {
    margin: 400px auto;
}

.smLoader {
    margin: 20px;
}


.smaller > div {
    flex-direction: column;
}

