.userAddingWrapper {
    display: flex;
}

.userAddingWrapper, 
.errorWrapper {
    margin-top: 12px;
    margin-bottom: 24px;    
}

.userAddingButton {
    min-width: 127px;
}

.departmentColorPickerIdentificator {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid var(--interactive-main-color);
}

.departmentsPicker {
    min-width: 300px;
    max-width: 300px;
}

.settings {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcomeMessage {
    margin-left: 12px;
    font-weight: 600px;
    font-size: 18px;
    line-height: 32px;
}

.settingsInputWrapper {
    margin-bottom: 16px;
    display: flex;
}

.settingsInputWrapper :first-child {
    min-width: 224px;
}

.goalPart {
    margin-left: 32px;
}

.oppOptionsDetails {
    font-style: italic;
    opacity: 0.8;
    margin-left: 8px;
}

.accountPlanTemplateTopBar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.accountPlanTemplateWrapper {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.accountPlanTemplateBadSectMsg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.accountPlanTemplateBadSectMsg > div {
    display: flex;
}

.accountPlanTemplateWrapperButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
}

.accountPlanTemplateWrapperButtons > div {
}

.accountPlanTemplateWrapperButtons button {
    margin-left: 6px;
}

.accountPlanTemplateWrapperButtons > div.accountPlanTemplateWrapperButtonsWrapper {
    margin-top: 24px; 
}

.accountPlanTemplateMsg {
    color: var(--link-color-main);
    margin-top: 16px;
    font-weight: 900;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.accountPlanTemplateModalStyle {
}

.accountPlanTemplateContent {
    border-bottom: 1px solid var(--light-grey-color);
}

@media screen and (max-width: 1094px) {
    .accountPlanTemplateModalStyle {
        width: 90% !important;
        height: 86% !important;
        min-width: 90%;
        min-height: 86%;
    }
}

.contactRoleName {
    min-width: 320px;
    margin-top: 4px;
    margin-right: 8px;
}

.contactRoleTypeIconsWrapper {
    display: flex;
    flex-wrap: wrap;
}

.iconWrapper{
    font-size: 2em;
    margin: 4px 12px;
    cursor: pointer;
    color: var(--link-color-main);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grow {
    flex-grow: 1;
}

.iconWrapper.selected {
    /* font-size: 2.5em; */
    /* margin-top: -2px;
    margin-left: -2px; */
    border-radius: 50%;
    border: 2px solid var(--link-color-main);
}

.addNewDepartmentButton {
    min-width: 210px;;
}

.departmentLabel {
    min-width: 200px;
    max-width: 200px;
}