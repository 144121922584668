@import url('../../theme.css');

.tableElement.closed {
    color: green;
}

.potentialElement .better {
    /* color: green; */
}
.potentialElement .worse {
    color: red;
    font-weight: bold;
}
.potentialElement .explanation {
    font-size: 14px;
    margin-bottom: 8px;
}

.tableWrapper {
    overflow-x: auto;
    /* overflow-y: auto; */
}



.whitespacingTable {
    overflow-y: auto;
}

.tableWrapper table thead tr th:first-child {
    z-index: 1111 !important;
}

.actionsRow {
    min-width: 114px;
    max-width: 114px;
}

.tableElement {
    height: 100%;
}

.tableWrapper .tableElement input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0 4px;
    background-color: transparent;
}

.tableWrapper td {
    border-right: 1px solid #E0E0E4 !important;
    border-top: 1px solid #E0E0E4 !important;
    padding: 12px 8px 10px 8px;
}

.tableWrapper tr td:first-child {
    border-left: 1px solid #E0E0E4 !important;
}

.tableWrapper tr td:last-child {
    border-right: 1px solid #E0E0E4 !important;
}

.tableWrapper tr:last-child td {
   border-bottom: 1px solid #E0E0E4 !important;
}

.selectableConfigSticky {
    top: 72px;    
    padding-bottom: 24px;
    box-sizing: content-box;
    margin: 0px;
}

.multipleExplanations {
    margin-bottom: 8px;
}

.red {
    color: red;
}
.bold {
    font-weight: bold;
}

.opportunity {
    color: var(--text-main-color);
}

.closed {
    color: green;
}


.oppCurrent,
.note,
.cachedInfo, 
.potentials div span:first-child {
    font-size: 14px;
}

.cachedInfo {
    cursor: pointer;
    color: var(--text-main-color);
    min-width: 136px;
}

.potentials {
    margin-right: 16px;
    font-weight: 500;
    font-size: 16px;
}

.potentials span:first-child {
    color: var(--text-main-color);
    margin-right: 8px;    
}
.potentials span:last-child {

}
.productUsage {
    width: 16px;
    height: 8px;
    border-radius: 4px;
}

.potentialLabel {
    font-weight: 600;
    margin-bottom: 8px;
}

.potential {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible !important;
}

.potentialLabel,
.potential {
    font-size: 14px;
    line-height: 16.42px;
    color: var(--text-secondary-color);
    cursor: pointer;
}

.na {
    color: var(--text-thertiary-color);
}

.opportunitiesCell {
    position: relative;
}

.opportunitiesCell,
.options {
    overflow: visible !important;
}

.opportunitiesCell .options{
    margin-left: -8px;
}


.override {
    color: var(--text-thertiary-color);
}

.strikeThrough {
    text-decoration: line-through;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.lastUpdatedWrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-bottom: 16px;
}


.label {
    color: var(--text-thertiary-color);
    font-weight: 500;
    font-size: 16px;
}

.filterWrapper {
    display: flex;
    flex-wrap: wrap;
}

.filterWrapper .section {
    width: 220px;
    margin-right: 32px;
    margin-bottom: 16px;
}

.accountNumberWrapper {
    font-size: 18px;
    font-weight: 500;    
    color: var(--text-main-color);
}

.accountNumberWrapper div:first-child {
    color: var(--interactive-main-color);
}

.accountNumberWrapper div:nth-child(2) {
    margin-left: 5px;
}