@import url('./theme.css');

.wrapper {
    display: flex;
    height: 100%;
    background-color: var(--background-secondary-color);
}

.content {
    flex-grow: 1;
    height: 100%;    
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
