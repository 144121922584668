@import url('../theme.css');

.label {
    margin-left: 8px;
    color: var(--text-main-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-main-color);
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    height: 44px;
    padding: 0 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: default;
}

.radio.checked {
    background-color: var(--interactive-main-color-10);
    border: 1px solid var(--interactive-main-color-50);
}

.radio input {
    -webkit-appearance: none;
    appearance: none;
    min-width: calc(12px + 2 * 2px);
    max-width: calc(12px + 2 * 2px);
    min-height: calc(12px + 2 * 2px);
    max-height: calc(12px + 2 * 2px);
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--interactive-main-color) !important;
}

.radio input:hover {
    border: 2px solid var(--interactive-main-color) !important;
    background-color: transparent;
}

.radio input:hover::after,
.radio input:checked::after {
    /* margin-top: -1px; */
    min-width: calc(12px - 4px);
    max-width: calc(12px - 4px);
    min-height: calc(12px - 4px);
    max-height: calc(12px - 4px);
    border-radius: 50%;
    content: '';
    background-color: var(--interactive-main-color);
}