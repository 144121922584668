@import url('../../../theme.css');

.threadSubject {
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 16px;
    color: var(--grey-color);
}

.fromToLabel {
    color: var(--grey-color);
    margin-right: 4px;
}

.body,
.snippet {
    margin-top: 4px 0px;    
    width: 1px;
    flex-grow: 1;
}

.accountLogo {
    width: 60px;
    height: 60px;
    border-radius: 2px;
    font-size: 60px;
    margin-right: 20px;
    overflow: hidden;
    color: var(--text-main-color);
    line-height: 60px;
}

.senderAndSubjectRow {
    white-space: nowrap;
}
.senderAndSubjectRow :last-child,
.snippet {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date {
    min-width: 100px;
    max-width: 100px;
}

.cursor {
    cursor: pointer;
}

.emailThreadWrapper {
    border-bottom: 1px solid var(--link-color-main);
    cursor: pointer;
}

.emailWrapper {
    font-size: 12px;
    margin-bottom: 12px;
}

.accountDetailsWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.opportunityInputsWrapper > * {
    margin-bottom: 12px;
}

.account { 
    display: flex;
    flex-direction: column;
}

.account .secondColumn {
    width: 40%;
}

.secondColumn .value {
    text-align: right;
    flex-grow: 1;
}

.rightSide {
    width: 45%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.opportunitiesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.opportunities {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.opportunitiesTopPart {
    height: 1px;
    flex-grow: 1;
}

.scroll {
    overflow-y: auto;
    min-height: 1px;
    height: 1px;
    flex-grow: 1;
}

.scrollableParent {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 168px;
    padding: 0;
}

.notesWrapper {
    flex-grow: 1;
    height: auto;
    overflow: auto;
    margin-right: -12px;
    padding-right: 12px;
}

.addNoteButtonWrapper {
    border-bottom: 1px solid #F6F6F6;
    padding: 0 16px;
}

.headingContent {
    color: var(--text-main-color);
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.healthscoreWrapper {
    color: var(--text-thertiary-color);
    font-size: 14px;
}

.row {
    display: flex;
    align-items: center;
}

.spaceBetween {
    justify-content: space-between;
}

.label {
    margin-right: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #A0A4AA;    
}

.accountDetails {
    margin-top: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color-main);
}

.offsetBottom {
    margin-bottom: 24px;
}

.orange {
    color: var(--link-color-main);
}

.half > * {
    width: 50%;
}

.half > *:first-child {
    margin-right: 30px;
    width: 55%;
}

.noCenterAlign {
    align-items: flex-start;
}

.oppButtons >:nth-child(2) {
    margin: 0 20px;
}

.rightPart {
    display: flex;
    align-items: center;
}

.actionButton {
    cursor: pointer;
    color: var(--link-color-main);

}

.rightPart > .actionButton {
    margin-left: 8px;
}

.loader {
    margin-top: 80px;
    margin-left: 20px;
}

.accountPlanTemplateWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 300px;
}

.addNoteButtonWrapper {
    display: flex;
    justify-content: flex-end;
}

.noteGroupWrapper {
    border-bottom: 2px solid var(--link-color-main);
    margin-bottom: 16px;
}

.noteContactName {
    color: var(--link-color-main);
}
.accountPlanModalWidth {
    width: 95vw;
}
.accountPlanContent {
    border-bottom: 1px solid var(--light-grey-color);
}

.accountPlanTemplateModal {
    color: var(--text-color-main);
}

.accountPlanTemplateModal > div:first-child {
    margin-bottom: 10px;
}

.accountPlanTemplateModal > div:last-child {
    margin-bottom: 10px;
}

.accountPlanTemplateModalButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
}

.accountPlanTemplateModalButtons > button {
    display: flex;
    margin-left: 8px;
}

.accountPlanTemplateModalButtonsMsg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.accountPlanMsg {
    color: var(--link-color-main);
    padding: 0px 0px 0px 0px;
    font-weight: 900;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.deliverableDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 335px;
}
.deliverableDueDate {
    overflow: hidden;
    width: 100px;
}

.deliverableDropdownHeader {
    width: 120px;
}

.missionStatementView {
    height: 166px;
}

.missionStatementView.bigger {
    height: 280px;
}

.bottomPart {
    display: flex;
    flex-grow: 1;
    min-height: 540px;
}

.bottomPart .leftSide {
    width: 302px;
    min-width: 250px;
    flex-grow: 0;
    height: 100%;
}

.actionsLabel {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
}
.actionsList {
    margin-top: 15px 0px;
    padding-top: 15px;
}

.actionListItem {
    color: var(--text-secondary-color);
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.actionListItem .indicator {
    width: 8px;
    height: 8px;
    background: var(--interactive-main-color);
    border-radius: 50%;
    margin-right: 5px;
    display: none;
}

.actionListItem.active {
    color: var(--text-main-color)
}

.actionListItem.active .indicator {
    display: block;
}