@import url('../theme.css');

.button {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;

    outline: none !important;
    box-shadow: none !important;

    height: 44px;
    color: #111311;    
    border-radius: 4px;
    background-color: var(--interactive-main-color);
    width: fit-content;
}

.clip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fullWidth {
    width: 100%;
}

.iconSpacing {
    height: 100%;
    min-width: 12px;
    max-width: 12px;
}

.button.icon,
.button.smallIcon,
.button.smallerIcon,
.button.transparentIcon,
.button.paddedIcon {
    padding: 0;
    min-width: 44px;
    max-width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var( --text-main-color);
}

.button.paddedIcon { 
    padding: 14px;
}

.button.smallIcon,
.button.button.smallerIcon,
.button.transparentIcon {
    border: none;
}

.button.smallIcon {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
}

.button.smallerIcon {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    padding: 5px;
}

.button.iconAndText {
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 18;
    font-weight: normal;
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var( --text-main-color);
}

.transparent {
    background: transparent;
    border: none;
    color: var(--text-thertiary-color);
}

.button:disabled {
    opacity: 0.7;
    cursor: default;
}