.options {
    margin-left: -8px;
    overflow: visible !important;
}

.options {
    position: absolute;
    width: 200px;
    padding: 8px;
    background-color: var(--table-dropdown-background);
    z-index: 12;
    margin-top: 12px;
    margin-left: -25px;
}

.option:first-child:hover,
.option.selected:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.option:last-child:hover,
.option.selected:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.option {
    padding: 8px;
    padding-left: 16px;
    color: var(--text-thertiary-color);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
}

.option:hover {
    background-color: var(--interactive-main-color-10);
}

.option.selected {
    background-color: var(--interactive-main-color-50);
}

.dropdownCell {
    width: 100%;
    height: 100%;
    background-color: var(--table-dropdown-background);

    color: var(--text-thertiary-color);
    font-size: 12px;
    font-weight: 500;
    min-width: 139px;
    padding: 12px 24px;
    position: relative;
    overflow: visible !important;
}

.dropdownValue {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    cursor: pointer;
}
