@import url('../../theme.css');

.loginAuth{
  background-color: #151916;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 100vh;
}

.loginAuth > div{
  margin-top: 2rem;
  max-width: 28rem;
}

.loginAuth h1{
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-top: 1.5rem;
  color: rgb(51, 56, 98);
  font-weight: inherit;
}

.loginAuthLogo{
  height: 5rem;
}

.loginAuthBoxLayout{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 3.5rem 2.5rem 2.5rem 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #A0FFBB;
}

.loginAuthBoxLayoutP{
  text-align: center;
  color: rgb(118, 122, 150);
  margin-top: 0.75rem;
}

.loginAuthBoxLayoutForm{
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
}

.loginAuthBoxLayoutForm.msSignIn {
  padding-top: 0;
}

.loginAuthBoxLayoutErrorWrapper{
  margin-top: '0.75rem';
}

.loginAuthBoxLayoutLoginButtonWrapper{
  display: flex;
  color: #878787;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.125rem;
  background-color: #151916;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
}

.loginAuthBoxLayoutLoginButtonWrapper:hover {
  box-shadow: 0px 0px 1px 1px #A0FFBB;
  color: white;
  background-color: #0E110E;
}

.loginAuthBoxLayoutIcon{
  background-color: rgb(255, 255, 255);
  padding: 0.625rem;
  border-radius: 0.125rem;
  height: fit-content;
  width: fit-content;
  line-height: 0px;
}

.loginAuthBoxLayoutText{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pointer{
  cursor: pointer;
}

.not-allowed{
  cursor: not-allowed;
}

