@import url('../../../../theme.css');

.active {
    color: var(--link-main-color);
}
.inactive {
    color: var(--link-thertiary-color);
}

.emailLabel {
    min-width: 25px;
    max-width: 25px;
}