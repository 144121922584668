@import url('../../theme.css');

.accountWrapper {
    display: flex;
    cursor: pointer;
    position: relative;
}

.accountInputsWrapper > * {
    margin-bottom: 12px;
}

.addButton {
    margin-right: 12px;
}

.accountReassignWrapper {
    min-height: 200px;
    height: 200px;
}