@import url('../../theme.css');

.impersonate {
    color: var(--link-color-main);
    cursor: pointer;
}

.email {
    color: var(--link-color-main);
    max-width: 300px;
    min-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limitsRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tableApiRequestsTable {
    margin-top: 16px;
}

.tableApiRequests {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.tableApiRequests > div:first-child::before {
    content: "Remaining: ";
}

.tableApiRequests > div:last-child::before {
    content: "Max: ";
}

