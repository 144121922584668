.opportunity {
    margin-bottom: 16px;
    font-size: 12px;    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.opportunityDataWrapper {
    cursor: pointer;
}

.opportunityDataWrapper.inactive {
    color: var(--grey-color);
}