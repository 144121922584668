@import url('../theme.css');

.popper {
    background: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin-left: 212px;
    margin-top: 0px;
    z-index: 100;
}

.smallerMargin {
    margin-left: 12px;
}

.datepicker {
}

.input {
    position: relative;
}

.calendarIcon {
    position: absolute;
    top: 14px;
    right: 14px;
}
