@import url('../theme.css');

.tabs {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    height: 44px;
}

.option {
    color: var(--text-secondary-color);
    font-style: normal;
    padding: 10px 14px;
    font-size: 18px;    
    font-weight: normal;
    cursor: pointer;
}

.row {
    display: flex;
    align-items: center;
}

.row div:nth-child(2) {
    margin-left: 4px;
}

.selected {
    font-size: 18px;
    color: var(--text-main-color);
    border-bottom: 1px solid var(--text-main-color);
}