
@import url('../../../../theme.css');

.wrappedTextContent {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.date {
    padding-top: 2px;
    min-width: 150px;
    max-width: 150px;
    margin-right: -8px;
}

.detailsWrapper {
    font-size: 12px;
}

.user img {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border-radius: 50%;
}

.smallOffset {
    width: 100%;
    height: 4px;
}

.commaSeparator {
    margin-left: -6px;
    margin-right: 8px;
}

.detaislLabel {
    margin-right: 4px;
}

.detailsData {
    color: var(--grey-color);
}

.body {
    color: var(--text-color-main);
}

.via {
    color: var(--grey-color);
    padding-top: 2px;
}

.author {
    flex-grow: 1;
}

.comment:not(.last) {
    border-bottom: 1px solid var(--light-grey-color)
}

.filePreview {
    min-width: 100px;
    max-width: 100px;
    max-height: 100px;
    margin: 0 8px;    
}

.filePreview.bigger,
.filePreview audio {
    min-width: 284px;
    max-width: 284px;
    width: 284px;
}

audio::-webkit-media-controls-panel {
    background-color: var(--link-color-main);
    border-color: var(--link-color-main);
    color: white;
}

.filePreview span {
    font-size: 10px;
    word-break: break-all;
}

.mainColor {
    color: var(--link-color-main);
    display: block;
    margin: 0 auto;
}

.commentButton {
    margin: 0 5px;
}

.commentButton:last-child {
    margin-right: 10px;
}