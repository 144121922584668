.grid {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.grid canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.peopleRow {
    display: flex;
}
.cell {
    min-width: 240px;
    max-width: 240px;
    min-height: 120px;
    max-height: 120px;
    border: 1px solid red;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.draggedElementsContainer {
    position: relative;
    /* pointer-events: none; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    transform-origin: top left;
}

.captureEvents {
    pointer-events: all;
}

.dragAnimation {
    transition: top 0.1s, left 0.1s;
    /* 
        There is a settimeout to clear the animation in JS
        If you change this, change the timeout as well
    */
}

.draggable {
    cursor: grab;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;

    transform: translateZ(0);
}

.draggable.dragging {
    cursor: grabbing;
}

.spacingRow {
    min-height: 60px;
    max-height: 60px;
}

.grid :global(.react-contexify) {
    position: absolute;
}