.slateEditor {
    border: 1px solid var(--light-grey-color);
    border-radius: 4px;
    min-width: 572px;
    width: 100%;
    min-height: 200px;
    overflow: scroll;
    resize: both;
    display: flex;
    flex-direction: column;
}

.slateButtons {
    margin-right: 10px;
}

.slateToolbar {
    border-bottom: 2px solid #eee;
    padding: 10px;
}

.slateEditable {
    position: inherit;
    padding: 10px;
    height: 1px;
    flex-grow: 1;
    min-height: fit-content;
}

.slateTable {
}

.fullWidth {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.slateEditor.viewOnly {
    resize: none;
    overflow: auto;
    border: none;
}
.noMinHeight {
    min-height: unset;
}

.smallFont {
    font-size: 12px;
    line-height: 14px;
    color: #A0A4AA;
}

.smallFont code {
    padding: 2px 2px 1px 2px;
}

.smallFont h1 {
    margin: 1px 0;
}

.slateEditor.viewOnly .slateEditable {
    pointer-events: none;
    cursor: default;
}

.slateTable td {
    border-top: 1px solid var(--light-grey-color);
    border-left: 1px solid var(--light-grey-color);
}

.slateTable tr:first-child td:first-child {
    border-top-left-radius: 4px;
}

.slateTable tr:first-child td:last-child {
    border-top-right-radius: 4px;
}

.slateTable tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
}

.slateTable tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
}

.slateTable tr th:last-child,
.slateTable tr td:last-child {
    border-right: 1px solid var(--light-grey-color);
}

.slateTable tr:last-child td {
    border-bottom: 1px solid var(--light-grey-color);
}

blockquote {
    border-left: 2px solid var(--light-grey-color);
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    font-style: italic;
}

code {
    background-color: var(--light-grey-color);
    padding: 4px 6px 4px 6px;
    color: var(--grey-color);
}
