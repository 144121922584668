@import url('../../../../theme.css');

.contact {
    --contact-width: 254px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    width: var(--contact-width);
    height: 143px;
    background: var(--table-header-background);
    padding: 10px 50px;
    position: relative;
    /* pointer-events: none; */
}

.firstCellSelected {
    border-width: 1px;
    border-color: var(--interactive-main-color);
    background: var(--table-header-background);
}

.bottomCircle {
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    background: var(--background-color);
    border-radius: 50%;
    border: 1px solid var(--border-color);
    color: var(--interactive-main-color);
    padding-left: 4px;
    padding-top: 1px;
}

.profileImage img {
    width: 100%;
    height: 100%;
}

.profileImage {
    border-radius: 50%;
    font-size: 10px;
    width: 34px;
    height: 34px;
    overflow: hidden;
}

.wrapper {
    width: 100%;
    justify-content: center;
}

.roleIcon {
    color: var(--link-color-main);
    position: absolute;
    background: white;
    border-radius: 50%;
    top: 26px;
    left: 9.5px;
    width: 16px;
    height: 16px;   
    font-size: 15px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.roleIcon > svg {
    display: block;
}

.closeButton {
    padding: 4px;
    position: absolute;
    top: 2px;
    right: 4px;
    font-weight: bolder;
    cursor: pointer;
}

.name {
    color: var(--text-main-color);
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.position {
    margin-top: 5px;
    border-radius: 4px;
    color: var(--background-color);
    font-size: 12px;
    font-weight: normal;
    height: 21px;
    line-height: 23px;
    padding: 0 7px;
    max-width: 180px;
}

.hidden {
    visibility: hidden;
}

.actionsWrapper {
    margin-top: 8px;
}
.actionsWrapper > *:not(:first-of-type) {
    margin-left: 5px;
}

.name,
.position {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.contactDetailsWrapper {
    position: relative;
}


.relationshipPicker,
.contactMarkingWrapper,
.colorPickerWrapper {
    width: var(--contact-width);
    background: var(--background-color);
    height: auto;
    padding: 12px;
    font-size: 10px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    position: absolute;
    top: 0;
    left: 0;
}