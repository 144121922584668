@import url('../../../../theme.css');

.missionStatement {
    font-size: 16px;
    color: var(--text-main-color);
    display: flex;
}

.buttons {
    color: var(--text-main-color);
    margin-left: 5px;
    width: 24px;
    display: flex;
    margin-top: 8px;
}

.buttons > * {
    margin-bottom: 5px;
}