@import url('../theme.css');

.background{
    border-radius: 0.375rem;
    background-color: var(--warning-yellow-background-color);
    padding: 1rem;
}

.errorChildren {
    width: 100%;
}

.background div{
    display: flex;
}

.show{
    visibility: visible;
}

.hide{
    visibility: hidden;
}

.icon{
    flex-shrink: 0;
    align-items: center;
}

.msg{
    margin-left: 0.75rem;
    flex-grow: 1;
}

.msg > div{
    color: var(--warning-orange-text-color);
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
}

.msg > div > p {
    width: 100%;
}
