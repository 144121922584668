@import url('../theme.css');

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 35px;
}

.navigationCenter {
    display: flex;
    width: 1px;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.navigation {
    display: flex;
    align-items: center;
}

.pageIndex {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid var(--border-color);
}

.pageIndex:nth-child(2) {
    border-left: none
}

.pageIndex.active {
    color: #171717;
    background: var(--interactive-main-color);
}

.endButton,
.pageIndex {
    width: 45px;
    height: 45px;
    color: var(--text-main-color);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.endButton * {
    color: var(--text-main-color);    
}

.endButton {
    background: transparent;
    border-radius: 0;
}

.extraBorderLeft {
    border-left: 1px solid var(--border-color);
}

.endButton:first-child {
    border: 1px solid var(--border-color);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.endButton:last-child {
    border: 1px solid var(--border-color);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.indexation {
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
    text-transform: uppercase;
    color: var(--text-thertiary-color);
}