@import url('../../../../theme.css');

.account {
    display: flex;    
    flex-grow: 1;
}

.linkedInIcon {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.accountDetailsWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.secondColumn .value {
    text-align: right;
    flex-grow: 1;
}

.headingContent {
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    position: relative;
    height: 22px;
}

.spaceBetween {
    justify-content: space-between;
}

.warning {
    user-select: none;    
    color: rgba(250, 204, 21, 0.8);
    margin-top: 2px;
}

.label {
    margin-right: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #A0A4AA;    
}

.accountDetails {
    margin-top: 18px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color-main);
}

.offsetBottom {
    margin-bottom: 24px;
}

.orange {
    color: var(--link-color-main);
}

.closeButton {
    cursor: pointer;
    font-weight: bolder;
}

.periodSelectionWrapper {
    background-color: white;
    border: 1px solid var(--link-color-main);
    border-radius: 4px;
    padding: 6px 16px;
    width: 160px;
    position: absolute;
    right: 0;
    z-index: 1111;
}

.selectLabel {
    font-size: 14px;
}

.periodLabel {
    font-size: 12px;
    margin: 4px 0px;
    cursor: pointer;
}

.green {
    color: #3EB984;
}

.red {
    color: red;
}

.graphButtonWrapper {
    margin-top: -9px;
    height: 16px;
}

.details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.column > *:not(:last-child) {
    margin-bottom: 25px;
}

.rowColumn {
    height: 100%;
    max-height: 400px;
    align-items: flex-start;
    column-gap: 50px;
}

.rowColumn .additionalDetails {
    max-width: 200px;
    margin-bottom: 40px;
    word-break: break-all;
}

.inTasks {
    margin-top: 40px;
}