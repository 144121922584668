@import url('../../../theme.css');

.moneyStatistic {
    width: 198px;
    color: var(--text-main-color);
    cursor: pointer;
}

.label {
    font-size: 12px;
    text-transform: capitalize;
    margin-bottom: 12px;
}

.grayWrapper {
    background: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 14px 12px;
}

.value {
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 8px;
}
