.notesWrapper {
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
}

.notesList {
    margin-right: 30px;
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 300px;
    width: 434px;
    max-width: 100%;
    margin-bottom: 10px;    
}

.noteDetails {
    width: 200px;
    flex-grow: 30;
    min-width: 200px;
    overflow-y: auto;  
}

.contactName {
    margin-top: 15px;
    margin-bottom: -15px;
}

.contactName:not(:first-of-type) {
    border-top: 1px solid var(--border-color);
    padding-top: 15px;
}