:root {
    --link-color-main: #F08178;
    --link-color-main-rgba-7: rgba(240, 129, 120, 0.7);
    --link-color-main-rgba-6: rgba(240, 129, 120, 0.6);
    --link-color-main-rgba-5: rgba(240, 129, 120, 0.5);
    --link-color-main-rgba-25: rgba(240, 129, 120, 0.25);
    --link-color-main-rgba-1: rgba(240, 129, 120, 0.1);
    --grey-color: #A0A4AA;
    --text-color-main: #484848;
    --light-grey-color: #E0E0E4;
    --lighter-grey-color: rgba(0, 0, 0, 0.033);
    --tw-ring-color-solid: rgba(59, 130, 246);
    --green-color: #33B679;
    --warning-yellow-background-color: #FEFCE8;
    --warning-orange-text-color: rgb(161 98 7);

    --red-color: #CB3D2C;
}

#root.darkTheme {
    --background-color: #151916;
    --background-secondary-color: #0E110E;
    --text-main-color: #FFFFFF;
    --text-secondary-color: #878787;
    --text-thertiary-color: #D3D0CF;

    --interactive-main-color: #A0FFBB;
    --interactive-main-color-10: rgba(160, 255, 187, 0.1);
    --interactive-main-color-50: rgba(160, 255, 187, 0.5);

    --table-header-background: #262D28;
    --table-dropdown-background: #111311;


    --border-color: #424944;
    --boxes-filled-color: #111311
}
#root.lightTheme {
    --background-color: #FFFFFF;
    --background-secondary-color: #E4E5E6;
    --text-main-color: #111311;
    --text-secondary-color: #424944;
    --text-thertiary-color: #1E1E1E;

    --interactive-main-color: #609970;
    --interactive-main-color-10: rgba(96, 153, 112, 0.1);
    --interactive-main-color-50: rgba(96, 153, 112, 0.5);

    --table-header-background: #F4F4F4;
    --table-dropdown-background: #ADB8AF;

    --border-color: #DCE0DD;
    --boxes-filled-color: #F4F4F4;
}