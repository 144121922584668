@import url('../theme.css');

.textarea {
    border: 1px solid var(--border-color);
    background: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    
    resize: none;
    width: 100%;
    height: 140px;
    display: block;
    position: relative;
}

.textarea.focused {
    border-color: var(--interactive-main-color);
}