@import url('../theme.css');

.loader,
.spinner {
    --small-size: 18px;
    margin: 0;    
}

.spinner{
    width: 4rem;
    height: 4rem;
    border-top: 3px solid var(--interactive-main-color);
    border-right: 3px solid transparent;
    border-radius: 50%;
    animation: rotation .8s linear infinite;  
}

.loader,
.spinner {
    width: var(--small-size);
    height: var(--small-size);
}

.loader.big,
.spinner.big {
    width: calc(3 * var(--small-size));
    height: calc(3 * var(--small-size));
    margin: 200px auto;
}

.loader.extraBig,
.spinner.extraBig {
    width: calc(6 * var(--small-size));
    height: calc(6 * var(--small-size));
    border-top-width: 5px;
    border-right-width: 5px;
    margin: 200px auto;
}

.smallerMargin { 
  margin: 100px auto;
}

@keyframes rotation{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}