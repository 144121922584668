@import url('../theme.css');

.sidebar {
    min-width: 84px;
    max-width: 184px;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--background-color);
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid var(--border-color);
}

.betaWrapper {
    margin-top: 40px;
}

.beta {
    font-size: 10px;
    margin-left: 12px;
    margin-top: -8px;
    color: var(--text-color-main)
}

.logo {
    margin-top: 24px;
    margin-bottom: 32px;
}

.logoPlacement{
    height: 44px;
    width: 44px;
    margin-left: auto;
    margin-right: auto;
}

.menuEntry,
.userWrapper {
    margin-bottom: 10px;
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.topPart{
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.bottomPart {
    margin-bottom: 18px;
}

.menuEntry button {
    color: var(--text-secondary-color) !important;
}

.menuEntry .selected {
    background-color: var(--interactive-main-color);
    color: #111311 !important;
}

.userImg,
.msInitials {
    height: 58px;
    width: 58px;
    border-radius: 50%;
}

.msInitials {
    background-color: var(--text-main-color);
    line-height: 58px;
    text-align: center;
    color: var(--background-color);
    font-weight: bold;
    font-size: 22px;
}
