@import url('../theme.css');

.subheader {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-main-color);
    padding: 8px;
    background-color: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    text-align: left;
    border-radius: 4px;
    padding-left: 20px;
    width: 100%;
}

.noBackground {
    background-color: transparent;
}