@import url('../theme.css');

.dropdown {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    color: var(--text-main-color);
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    width: 384px;    
    cursor: pointer;
    background-color: var(--background-color); 
}

.dropdown * {
    box-shadow: none !important;
    height: 20px;
    padding: 5px 10px;
}

.dropdown option:hover {
    background: var(--interactive-main-color-10) !important;
}

.dropdown option:checked {
    background: var(--interactive-main-color-50) !important;
}

.dropdown.smallerWidth {
    width: 124px;
}

.dropdown.fullWidth {
    width: 100%;
}

.labelOption {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 8px;
    display: block;
}
