@import url('./../../theme.css');

.clarificationOffset {
    margin-left: 16px;
}

.customObjectDropdown {
    margin-right: 32px;
}

.rowInputDropdown {
    margin-left: 0px;
}

.smallDropdown {
    margin-right: 16px;
    width: 86px;
    max-width: 86px;
}

.colorWrapper {
    margin-left: 32px;
}

.colorWrapper .basicOption {
    min-width: 60px;
}

.deleteIcon {
    color: var(--link-color-main);
    margin-left: 16px;
    cursor: pointer;
}

.ruleDropdown input {
    box-shadow: none !important;
}

.basicOption {
    min-width: 164px;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.radioWrapper > * {
    margin-right: 80px;
}

.half {
    width: 50%;
}

.half.textareaWrapper {
    margin-left: 16px;
    margin-top: -24px;    
}

.textareaRow {
    align-items: baseline;
}

.optionsPart {
    align-items: baseline;
}

.textarea {
    height: 166px;
}

.comparidonDropdown {
    margin-right: 32px;
}