@import url('../../theme.css');

.opportunities {
    display: flex;
    cursor: pointer;
}

.dropdownIndicator {
    margin-left: 8px;
}

.dueDateCalendarWrapper {
    position: absolute;
    right: -20px;
    margin-top: 20px;
}

.dueDateCalendarWrapper,
.modalCalendarWrapper {
    z-index: 1111111;

    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
}

.checkboxes > * {
    margin: 8px 0;
}

.dueDate {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
    position: relative;
}

.tableContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* height: 100%; */
}

.dueDateExplanation {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A0A4AA;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;
}

.taskInputsWrapper > * {
    margin-bottom: 12px;
}

.taskInputsWrapper > *:last-child {
    margin-bottom: 0;
}

.sfObjectsCounter {
    display: flex;

}

.sfObjectsList {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.name {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.name > span {
    margin-left: 8px;
}

.filterOnColorWrapper {
    margin-right: 8px;
    border-radius: 4px;
    padding: 4px 8px;
}

.taskDetailsWrapper {
    display: flex;
    position: relative;
}

.taskDetailsWrapper > * {
    width: calc(50% - 8px);    
}

.taskDetailsWrapperContactsWrapper {
    min-height: 182px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.separator {
    margin-top: 16px;
    margin-bottom: 8px;
}

.descriptionModalWrapper {
    flex-grow: 1;
}

.descriptionModalWrapper > span {
    margin-left: 18px;
    display: block;
    min-height: 300px;
}

.taskActions {
    justify-content: space-between;
    display: flex;
    position: relative;
    align-items: center;
}

.modalCalendarWrapper {
    position: absolute;
    right: -1px;
    top: -1px;
}

.leftPart {
    flex-grow: 1;
    max-width: 700px;
    min-width: 600px;
    width: 1px;
}

.rightPart {
    flex-grow: 2;
    width: 1px;
}

.rightPart > *,
.leftPart > * {
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.notesWrapper {
    flex-grow: 1;
    height: 1px;
    overflow: auto;
    margin-right: -12px;
    padding-right: 12px;
}

.noteWrapper {
    border-bottom: 1px solid #F6F6F6;
    padding: 16px;
}

.noteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.noteCreator {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color-main);
}

.noteCreatedSince {
    margin-left: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #A0A4AA;
}

.noteTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color-main);
    margin-top: 8px;
}

.noteBody {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A0A4AA;
}

.addNoteButtonWrapper {
    float: right;
}

.reassignDropdown {
    width: 120px !important;
}

.tableSearch {
    margin-bottom: 1px !important;
    width: 288px;
}

.break,
.space {
    flex-basis: 100%;
    height: 0;
}

.marginBottom {
    margin-bottom: 12px !important;
}

.smallerScreenInput {
    flex-basis: 100%;
}
.smallerScreenInput > * {
    width: 100%;
}

.noMarginRight {
    margin-right: 0;
}

.grow {
    white-space: nowrap;
}

.accountNameButton,
.tableContent {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

@media (max-width: 1400px) {
    .taskDetailsWrapper {
        flex-direction: column;
    }
    
    .taskDetailsWrapper > *,
    .leftPart,
    .rightPart,
    .notesWrapper {
        width: 100%;
        max-width: 100%;
        min-height: 500px;
    }    
}