@import url('../../theme.css');

.noteWrapper {
    padding: 15px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    margin-top: 15px;
    width: 100%;
    /* width: 432px; */
    height: 187px;
}


.noteWrapper.selected {
    background-color: var(--boxes-filled-color);
    border: 1px solid var(--interactive-main-color);
}

.actionButton {
    color: var(--interactive-main-color) !important;
}


.noteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-color);
    height: 40px;
}
.noteCreator {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-main-color);
}

.noteCreatedSince {
    white-space: nowrap;
    font-weight: normal;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.bottomPart {
    margin-top: 20px;
    margin-bottom: 10px;
}

.noteTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-main-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.noteBody {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #A0A4AA;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 72px !important;
    min-height: 72px !important;
}

.notesContainerWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;   
}
