@import url('../../theme.css');

.opportunitiesManagementWrapper {
    height: calc(100% - 140px);
    margin-bottom: 16px;
    padding-right: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;    
}

.boardContainer {
    height: 1px;
    flex-grow: 1;
    overflow: auto;
    padding-right: 4px;
    display: grid;
    grid-auto-flow: column;
}

.headerWrapper {
    width: 100%;
    position: sticky;
    top: 7px;
    margin-bottom: 7px;
    background-color: var(--table-header-background);
    z-index: 100;
}

.dropdownWrapper {
    width: 200;
    margin-left: 5px;
}

.boardContainer > :nth-child(2) {
    margin-left: 0;
}

.column {
    background: var(--background-secondary-color);
    margin: 0 12px;
    padding: 8px;    
    border-radius: 6px;
    padding-top: 0px;
    position: relative;
    min-width: calc(266px + 2*8px);
    max-width: calc(266px + 2*8px);
    justify-self: stretch;
    display: flex;
    flex-direction: column;
}

.column .topDraggableColumn {
    position: sticky;
    top: 0;
    z-index: 100;
    display: block;
    width: 282px;
    min-width: 282px;
    max-width: 282px;
    left: 0;
    margin-left: -8px;
    margin-top: -1px;
    background-color: var(--background-secondary-color);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 7px;
    max-height: 7px;
}

.dataDetails {
    padding: 0 10px;
}

.colorPickerWrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 14px;    
    margin-right: 20px;
    z-index: 110;
}

.columnHeader {
    width: 100%;
    height: 40px;
    background: var(--table-header-background);
    border-radius: 6px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    /* identical to box height */

    text-transform: uppercase;
}

.columnContainer {
    width: 100%;
    min-height: 100%;
}
.draggingOver {
    background-color: var(--interactive-main-color-50);
}

.opportunity {
    user-select: none;
    width: 100%;
    height: 140px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 16px 12px;
}


.title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.03em;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
}

.edit {
    color: var(--interactive-main-color) !important;
    cursor: pointer;
}

.trash {
    color: var(--red-color) !important;
    cursor: pointer;
    margin-left: 8px;
}

.account,
.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-secondary-color);    
}

.date:nth-child(2) {
    margin-top: 4px;
    margin-bottom: 4px;
}

.date:nth-child(3) {
    white-space: nowrap;
    overflow: hidden;
    min-width: fit-content;
}

.dates {
    margin-top: 16px;
    min-width: 100px;
    text-align: right;
}

.am,
.amount {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-thertiary-color);
    margin-top: 10px;
}

.beingDragged {
    border: 1px solid var(--interactive-main-color);
}

.topNav > * {
    margin-right: 24px;;
}

.filtersLabel {
    min-width: 170px;
    max-width: 170px;
}

.smallInput {
    width: 80px;
}

.smallDropdown {
    width: 136px;
}

.pushCloseDateWrapper :nth-child(2) {
    margin: 0 8px;
}