.productUsage {
    width: 28px;
    height: 8px;
    border-radius: 4px;
}

.gray {
    background-color: #878787;
    color: white;
}
.red {
    background-color: #CB3D2C;
    color: white;
}
.yellow {
    background-color: #FFC909;
    color: #111311;
}
.green {
    background-color: #A0FFBB;
    color: #111311;

}
