@import url('../../../../theme.css');

.noteBody {
    color: var(--text-secondary-color);
    font-size: 14px;
    height: 1px;
    flex-grow: 1;
}

.noteTitle {
    font-size: 22px;
    color: var(--text-main-color);
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 25px;
}

.focusedNote {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}