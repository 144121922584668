@import url('../../../../theme.css');

.inactiveTableElement {
    background-color: var(--lighter-grey-color);
    height: 100%;
    width: 100%;
    padding: 12px 4px;
    cursor: not-allowed;
}

.missingProduct {
    color: red;
}