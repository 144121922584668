@import url('../theme.css');

.actions {
    display: flex;
    justify-content: flex-end;
}

.actions > * {
    cursor: pointer;
    padding: 0 8px;
    color: var(--link-color-main);
}

.description {
    font-size: 12px;
    margin-top: -12px;
}

.openAiDisclaimer {
    margin-top: 20px;
    font-size: 10px;
    font-style: italic;
    display: block;
    margin-bottom: -4px;
}

.suggestedTasksModal {
    min-width: 620px;
}