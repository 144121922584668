@import url('../theme.css');

.viewContainer {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    width: calc(100% - 2 * var(--x-offset));
    margin: 20px;
    min-height: fit-content;
    height: fit-content;
    flex-grow: 1;
    background: var(--background-color);
    border-radius: 2px;
    padding: 15px 20px;
}

.expanded {
    margin-bottom: 10px;
}

.noGrow {
    flex-grow: 0;
}

.viewContainer.xScroll {
    width: unset;
    max-width: calc(100% - 2 * var(--x-offset));
    overflow-x: auto;
}

.viewContainer.noOffset {
    padding: 0;    
}

.collapsableHeader {
    cursor: pointer;
}
