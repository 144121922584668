.tableElement {
    width: 100%;
    position: relative;
}

.tableElement input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0 4px;
}