@import url('../theme.css');

.header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 20px;
    top: 0px;
    position: sticky;
    z-index: 999;
    background-color: var(--background-color);
    box-sizing: content-box;
}

.topNavBarPortal {
    display: flex;
}

.pathLabel {
    color: var(--text-main-color);
    font-size: 26px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pathLabel * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.firstHalf {
    color: var(--text-secondary-color);
}
.secondHalf {
    margin-left: 4px;
}

.header.crm {
    justify-content: end;
}

.shown {
    display: block;
}

.hidden {
    display: none;
}


.userDetails {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-main-color);
    margin: 0 12px;
}

.menuItem {
    text-align: right;
}

.searchWrapper {
    flex-grow: 1;
    margin-right: 32px;
    min-width: 150px;
}


.backButton {    
    color: var(--text-main-color);
    cursor: pointer;
    margin-right: 10px;
}

.globalErrorMessagePreWrap > div:first-child {
    display: inline-block;
}

.closeGlobalErrorButton {
    margin-left: 12px;
    color: var(--interactive-main-color);
    cursor: pointer;
}

.clearSearchButton {
    font-size: 22px;
    margin-top: 1px;
}

#user:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow);
  --tw-ring-offset-color: #1f2937;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

