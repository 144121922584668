@import url('../../../theme.css');

.heading {
    padding-left: 20px;
    padding-right: 5px;
    margin-bottom: 0px;
}

.draggablePlay {
    background-color: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 0 12px;
    margin: 8px 0px;
}

.slateEditor {
    cursor: text;
}

.dueDateLabel {
    min-width: 300px;
}

.sequenceWrapper {
    font-size: 14px;
    position: relative;
    min-height: 1700px;   
    overflow: auto;
}

.sequenceWrapper > div {
    cursor: pointer;
}

.sendEmail,
.manualTask {
    border: 1px solid var(--link-color-main);
    width: fit-content;
    padding: 8px 16px;
    border-radius: 4px;
}

.explanation {
    font-size: 10px;
    display: flex;
    align-items: center;
}

.explanation div {
    margin-left: 4px;
}

.negative {
    color: red;
}

.positive {
    color: green;
}

.hidden {
    display: none;
}
#test-id-1,
#test-id-2,
#test-id-3,
#test-id-4,
#test-id-5,
#test-id-6,
#test-id-7,
#test-id-8,
#test-id-9,
#test-id-10,
#test-id-11,
#test-id-12,
#test-id-13,
#test-id-14,
#test-id-15,
#test-id-16,
#test-id-17,

#test-id-21,
#test-id-22,
#test-id-23,
#test-id-24,
#test-id-25,
#test-id-26,
#test-id-27,
#test-id-28,
#test-id-29,
#test-id-30,
#test-id-31,
#test-id-32,
#test-id-33,
#test-id-34,
#test-id-35 {
    position: absolute;
    width: 240px;
    text-align: center;
    justify-content: center;
    background-color: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    z-index: 2;
    margin-left: 400px;
}
#test-id-1 {
    left: 710px;
    top: 20px;
}

#test-id-2 {
    left: 710px;
    top: 100px;
}

#test-id-3 {
    left: 710px;
    top: 200px;
}

#test-id-4 {
    left: 710px;
    top: 300px;
}
 
#test-id-5 {
    left: 710px;
    top: 400px;
}

#test-id-6 {
    left: 710px;
    top: 480px;
}

#test-id-15 {
    left: 350px;
    top: 640px;
}

#test-id-29 {
    left: -10px;
    top: 800px;
}

#test-id-8 {
    left: -10px;
    top: 860px;
}

#test-id-16 {
    left: -10px;
    top: 960px;
}

#test-id-17 {
    left: -10px;
    top: 1060px;
}

#test-id-31 {
    left: -370px;
    top: 1200px;
}

#test-id-23 {
    left: -370px;
    top: 1260px
}

#test-id-32 {
    left: 350px;
    top: 1200px;
}

#test-id-12 {
    left: 350px;
    top: 1260px
}

#test-id-30 {
    left: 710px;
    top: 800px;
}

#test-id-21 {
    left: 350px;
    top: 1340px;
}

#test-id-33 {
    left: -10px;
    top: 1460px;
}

#test-id-35 {
    left: -10px;
    top: 1520px;
}



#test-id-34 {
    left: 710px;
    top: 1460px;
}

#test-id-22 {
    left: 710px;
    top: 1520px;
}

#test-id-27 {
    left: 710px;
    top: 2200px;
}

#test-id-10 {
    left: 710px;
    top: 860px;
}

#test-id-7 {
    left: 1070px;
    top: 640px;
}


#test-id-9 {
    /* top: calc(240px + 20px);
    left: 775px; */
}

#test-id-10 {
    /* top: calc(340px + 20px);
    left: -15px; */
}

#test-id-11 {
    /* top: calc(340px + 20px);
    left: 775px; */
}

#test-id-12 {
    /* top: calc(420px + 20px);
    left: -15px; */
}

#test-id-13 {
    /* top: calc(420px + 20px);
    left: 775px; */
}

#test-id-17 {
    /* top: calc(320px + 20px);
    left: 510px; */
}

.interactionIndicator {
    float: right;
    display: block;
    margin-top: 3px;
    margin-right: -10px;
    color: var(--grey-color);
}

.multiLine {
    margin-top: -8px;
}

.multiLine2 {
    margin-top: -16px;
}

.multiLine3 {
    margin-top: -24px;
}

.richTextWrapper {
    min-height: 200px;
}

#border1,
#border2,
#border3,
#border4,
#border5,
#border6,
#border7,
#border8,
#border9,
#border10,
#border11,
#border12,
#border13,
#border14,
#border15 {
    position: absolute;
}

#border1{
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 500px;
    position: absolute;   
    z-index: 1;
    top: 20px;
    left: 1230px;
}

#border2 {
    top: 620px;
    left: 870px;
    width: 720px;
    height: 50px;
    border-left: 2px solid var(--interactive-main-color);
    border-top: 2px solid var(--interactive-main-color);
    border-right: 2px solid var(--interactive-main-color);
    border-radius: 4px;
}

#border3 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 40px;
    position: absolute;   
    z-index: 1;
    top: 580px;
    left: 1230px;
}

#border4 {
    top: 752px;
    left: 515px;
    width: 715px;
    height: 50px;
    border-left: 2px solid var(--interactive-main-color);
    border-top: 2px solid var(--interactive-main-color);
    border-right: 2px solid var(--interactive-main-color);
    border-radius: 4px;
}

#border5 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 53px;
    position: absolute;
    z-index: 1;
    top: 815px;
    left: 515px;
}
#border6 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 53px;
    position: absolute;
    z-index: 1;
    top: 915px;
    left: 515px;
}

#border7 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 53px;
    position: absolute;
    z-index: 1;
    top: 1015px;
    left: 515px;
}

#border8 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 53px;
    position: absolute;
    z-index: 1;
    top: 815px;
    left: 1229px;
}

#border9 {
    top: 1155px;
    left: 150px;
    width: 720px;
    height: 50px;
    border-left: 2px solid var(--interactive-main-color);
    border-top: 2px solid var(--interactive-main-color);
    border-right: 2px solid var(--interactive-main-color);
    border-radius: 4px;
}

#border10 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1105px;
    left: 516px;
}

#border11 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1212px;
    left: 150px;
}

#border12 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1212px;
    left: 869px;
}

#border13 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1298px;
    left: 869px;
}

#border14 {
    top: 1420px;
    left: 505px;
    width: 725px;
    height: 50px;
    border-left: 2px solid var(--interactive-main-color);
    border-top: 2px solid var(--interactive-main-color);
    border-right: 2px solid var(--interactive-main-color);
    border-radius: 4px;
}

#border15 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1370px;
    left: 869px;
}

#border16 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1470px;
    left: 504px;
}

#border17 {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 1470px;
    left: 1229px;
}

#border18  {
    border-left: 2px solid var(--interactive-main-color);
    width: 1px;
    height: 52px;
    position: absolute;
    z-index: 1;
    top: 700px;
    left: 870px;
}

.simpleCell {
    position: absolute;
    border: 1px solid green;
    width: 150px;
    height: 70px;
    cursor: pointer;
}

.simpleGrid {
    position: relative;
    width: 10000px;
    height: 7500px;
    border: 2px solid red;
}

.currentStep {
    border: 2px solid;
    animation-name: highlight;
    animation-duration: 0.75s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.nextStep {
    border: 2px solid;
    animation-name: highlight;
    animation-duration: 0.75s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@keyframes highlight {
    from {border-color: var(--interactive-main-color);}
    to {border-color: var(--border-color);}
  }

@keyframes highlightSecondary {
    from {border-color: var(--interactive-main-color-10);}
    to {border-color: var(--border-color);}
  }