@import url('../theme.css');

.readableValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.readable {
}
.wrapper {
    width: 384px;        
    position: relative;
    height: 48px;
}

.wrapper, .textValue {
    padding: 12px 16px;
}

.wrapper,
.dropdownContent {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    color: var(--text-main-color);
    background-color: var(--background-color);
}

.dropdownContent {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    border-top: none;
    margin-top: 46px;
    z-index: 1000;
}

.dropdownContent > *:hover {
    background-color: var(--interactive-main-color-10);
}

.textValue {
    cursor: pointer;
}