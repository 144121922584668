*, ::before, ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, 1.0);
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

html {
    -moz-tab-size: 4;
    tab-size: 4;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button, select {
    text-transform: none;
}

button, input, optgroup, select, textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

button, [role="button"] {
    cursor: pointer;
}
button {
    background-color: transparent;
    background-image: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
    -webkit-appearance: button;
}

img {
    border-style: solid;
}

img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}

img, video {
    max-width: 100%;
    height: auto;
}

table {
    border-spacing: 0px;
}

a {
    color: var(--text-thertiary-color);
    text-decoration: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
