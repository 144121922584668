@import url('../../../theme.css');

.loader {
    margin: auto;
    margin-top: 200px;
}

.mappingWrapper {
    justify-content: flex-end;
    background: var(--background-secondary-color);    
    padding: 20px;
}

.exportButtonWrapper {
    position: absolute;
    right: 335px;
    bottom: 35px;
    z-index: 10;
}

.labelTrashIcon {
    pointer-events: all;
}

.exportableContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;   
    background: var(--background-color);
    background-image: radial-gradient(var(--text-secondary-color) 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -19px -19px;
    border: 1px solid var(--border-color); 
    border-radius: 2px;
}

.onTop {
    z-index: 15;
    padding: 30px 20px 0 20px;
}

.divisionColorsCheatSheet {
    position: absolute;
    right: 200px;
    top: 74px;
    font-size: 12px;
    line-height: 10px;
    cursor: pointer;
    background-color: #E5E5E5;
    padding: 4px;
    padding-top: 5px;
    border: 1px solid var(--link-color-main);
    border-radius: 4px;
}

.divisionColor {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    border-radius: 2px;
}

.addNoteButtonWrapper {
    border-bottom: 1px solid #F6F6F6;
    padding: 0 16px;
    display: flex;
    justify-content: flex-end;
}