@import url('./../theme.css');

.timedNotification {
    position: absolute;
    transition: top 0.5s;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 100000000;
    color: var(--text-main-color);
}

.timedNotificationDetails {
    background: var(--background-secondary-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding-left: 8px;
    height: 28px;
    max-height: 28px;
}

.hiding {
    top: -200px;
}
.showing {
    top: 200px;
}