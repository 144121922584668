.row {
    display: flex;
    align-items: center;    
    margin: 12px 0px;
}

.spaceBetween {
    justify-content: space-between;
}

.columnFlex {
    flex-direction: column;
}

.noAligning { 
    align-items: unset;
}

.fullWidth {
    width: 100%;
}

.flexGrow {
    flex-grow: 1;
}

.fullHeight {
    height: 1px;
    flex-grow: 1;
}

.wrap {
    flex-wrap: wrap;
}

.noWrap {
    flex-wrap: nowrap;
}

.small > *:first-child,
.normal > *:first-child,
.large > *:first-child,
.large_2 > *:first-child,
.large_3 > *:first-child {
    margin-left: 0px;
}

.small > *:last-child,
.normal > *:last-child,
.large > *:last-child,
.large_2 > *:last-child,
.large_3 > *:last-child {
    margin-right: 0px;
}

.small > * {
    margin-left: 8px;
    margin-right: 8px;
}
.normal > * {
    margin-left: 12px;
    margin-right: 12px;
}
.large > * {
    margin-left: 16px;
    margin-right: 16px;
}
.large_2 > * {
    margin-left: 20px;
    margin-right: 20px;
}
.large_3 > * {
    margin-left: 24px;
    margin-right: 24px;
}

.endSmall > *:last-child {
    margin-right: 8px;
}
.endNormal > *:last-child {
    margin-right: 12px;
}
.endLarge > *:last-child {
    margin-right: 16px;
}
.endLarge_2 > *:last-child {
    margin-right: 20px;
}
.endLarge_3 > *:last-child {
    margin-right: 24px;
}
.noMargin {
    margin: 0;
}

.endAlign {
    justify-content: end;
}