.healthScore {
    display: flex;
    justify-content: center;
    align-items: center;   
}

.pointer {
    cursor: pointer;
}

.healthScore .text {
    margin: 0 8px;
}


.healthScore .text {
    margin: 0 8px;
}

.positiveTrend {
    color: green;
}
.negativeTrend {
    color: red;
}

.lineWrapper {
    max-height: calc(100% - 8px);
    min-height: calc(100% - 8px);
}

.flipped {
    transform: scaleY(-1);
}