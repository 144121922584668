@import url('../theme.css');

.label {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary-color);
    margin-bottom: 8px;
}

.label.required::after {
    position: relative;
    content: "*";
    color: var(--interactive-main-color);
    margin-left: 8px;
}

.label.preventBreaks {
    white-space: nowrap;
}

.label.noMargin {
    margin: 0;
}

.fullWidth {
    width: 100%;
}