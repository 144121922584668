@import url('../theme.css');

.dataTable {
    width: 100%;
    table-layout:fixed;
}

.dataTable thead {
    background-color: var(--table-header-background);
    text-align: left;
}

.dataTable thead th {
    top: 0;
    background-color: var(--table-header-background) !important;
    z-index: 10;
}

.dataTableSticky th {
    position: sticky;
}

.dataTable.borderless td {
    border-right: 0;
    border-left: 0;
    padding-top: 7px;
    padding-bottom: 7px;    
}

.dataTable.borderless thead th {
    border: none;
    height: 44px;
}

.dataTable.borderless thead th,
.dataTable.borderless td {
    padding-left: 10px;
}

.dataTableCellHideOverflow {
    overflow: hidden;
    text-overflow: ellipsis; 
}

.dataTableCellHideOverflow div,span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dataTable.resizible tr th:last-child {
    padding-right: 0;
}

.dataTable thead th {
    font-style: normal;
    color: var(--text-main-color);
    padding: 10px 12px;
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    height: 67px;
}

.dataTable.resizible thead th {
    padding-right: 0;
}

.dataTable tr td {
    color: var(--text-thertiary-color);
    height: 1px;
    padding: 12px 10px;
    height: 57px;
    font-weight: 600;
    font-size: 14px;
}

.dataTable tr td:first-child,
.dataTable tr th:first-child {
    background-color: var(--background-color);
}

.dataTableThWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* overflow: hidden; */
    /* padding: 0px;
    margin: 0px;   */
    /* padding: 8px 0;
    padding-right: 16px; */
}

.dataTableThWrap div:first-child {
    flex-grow: 2;
}

.dataTableThSubWrap {
    overflow: hidden;
}

.dataTableThArrows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 8px 8px 8px 6px;
    cursor: pointer;
}

.dataTable td,
.dataTable th {
    border: 1px solid var(--border-color);
}

.dataTable th:not(:first-child) {
    border-left: none;
}

.dataTable th:not(:last-child) {
    border-right: none;
}

.dataTable thead tr th:last-child .dataTableThArrows {
    border-right: 0px;
    padding-right: 0px;
}

.dataTableFilterWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 6px;
}

.headerNonDataWrapper {
    display: flex;
    position: relative;
    margin-left: 24px;
}

.resizeBorder {
    height: 46px;
    width: 5px;
    padding: 0 2px;    
    cursor: col-resize;
    position: absolute;
    top: -23px;
    right: -1px;
    border-radius: 8px;
}

.resizeBorder.resizeSortBorder {
    top: 8px;
}

.resizeBorder div {
    background-color: var(--border-color);
    width: 2px;
    height: 100%;
}

.resizible .dataTableThArrowsBorder {
    border: none;
}

th .topPart {
    line-height: 20px;
}

th .topPart,
th .bottomPart {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}

th .bottomPart {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
}

.dataTable thead th:not(:first-child).noPaddingHeader,
.dataTable thead th:not(:first-child).noPaddingRightHeader {
    padding-left: 0;
    padding-left: 0;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}

.dataTable.sortable thead th {
    padding-right: 0;
}