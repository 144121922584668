@import url('../theme.css');

.modalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal,
.modalBig,
.modalSlightlyBigger {
    height: auto;
    padding: 30px 24px 28px 24px;    

    background-color: var(--background-color);
    border-radius: 6px;  

    max-height: 86%;
    display: flex;
    flex-direction: column;
}

.minHeightSmall {
    min-height: 50%;
    height: 50%;
}

.minHeightSmall .content {
    flex-grow: 1;
}

.minHeight {
    min-height: 80%;
    height: 80%;
}

.minWidth {
    min-width: 80%;
    width: 80%;
}

.minHeight .content {
    flex-grow: 1;
    min-height: cal(100% - 40px);
}

.content {
    height: 100%;
}

.modal {
    width: 585px;
    max-width: calc(100vw - 46px);
}

.modalSlightlyBigger {
    width: 700px;
    max-width: calc(100vw - 46px);
}

.modalBig {
    width: fit-content;
    max-width: calc(100vw - 46px);
}

.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLabel {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: var(--text-main-color);
}

.closeButton {
    cursor: pointer;
}

.content {
    margin: 12px 0px;
    overflow-y: auto;
    padding-top: 4px;
    padding-bottom: 4px;
}

.buttons {
    width: 100%;
}

.buttons > *:nth-child(2),
.buttons > *:nth-child(3),
.buttons > *:nth-child(4) {
    margin-top: 10px;
}
