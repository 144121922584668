@import url('../../../../theme.css');

.contacts {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contactsHeadingWrapper {
    margin-top: 0px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scrollableParent {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    min-height: 168px;
    padding: 0;
    flex-grow: 1;
}

.scroll {
    overflow-y: auto;
    min-height: 1px;
    height: 1px;
    flex-grow: 1;
}

.interactionIcon {
    color: var(--link-color-main);
    cursor: pointer;
}

.interactionIcon:nth-child(2) {
    margin: 0 8px;
}