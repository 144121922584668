.attachment {
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid var(--link-color-main);
    padding: 2px 0px 2px 6px;
    max-width: 200px;
    min-width: 200px;
    max-height: calc(16px + 2*6px);
    min-height: calc(16px + 2*6px);
    white-space: nowrap;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 4px;
}
.attachment.noDeleteButton {
    padding-right: 6px;
}
.attachment img {
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;    
}
.attachment .name {
    margin: 0 8px;
    color: var(--link-color-main);
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachment .size {
    color: var(--grey-color);
    font-size: 10px;
    margin-left: auto;
}