.EditorValueDiv {
    margin: 0 0 0.5em;
    color: #404040;
    font: 12px monospace;
    white-space: pre-wrap;
    padding: 10px 20px;
}

.EditorValueDivDefault {
    font-size: 14px;
    padding: 5px 20px;
    color: #404040;
    border-top: 2px solid #eeeeee;
    background: #f8f8f8;
}

.InstructionDivStyle {
    white-space: pre-wrap;
    margin: 0 -20px 10px;
    padding: 10px 20px;
    font-size: 14px;
    background: #f8f8e8;
}

.slateMenu > * {
    display: inline-block;
}

.slateName > * + * {
    margin-left: 15px;
}

