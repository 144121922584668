
.opportunities {
    display: flex;
    cursor: pointer;
}

.dropdownIndicator {
    margin-left: 8px;
}

.checkboxes > * {
    margin: 8px 0;
}

.dueDate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    height: 100%;
}

.tableContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.dueDateExplanation {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A0A4AA;
}

.actionWrapper {
    padding: 0;
    height: 14px;
}

.actions {
    display: flex;    
    width: 100%;
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: -12px;

}

.actions > :first-child {
    margin-right: 16px;
}