.userEditLabel {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}

.expandLine {
    cursor: pointer;
    margin-top: 20px;
    border-top: 2px solid var(--interactive-main-color);
    padding-bottom: 4px;
    color: var(--interactive-main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.expandLine.expanded {
    border-bottom: 2px solid var(--interactive-main-color);
    border-top: none;
    margin-top: 0;
    padding-bottom: 0px;
    margin-bottom: 20px
}