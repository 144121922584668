@import url('./../../theme.css');

.googleDoc {
    height: 100%;
}

.options {
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    padding-left: 20px;
    position: relative;
}

.notification {
    position: absolute;
    border-radius: 2px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    width: 100%;
    left: 10px;
    text-align: center;
    padding: 5px 0;
    z-index: 10000;
}

.optionForSelection {
    cursor: pointer;
    margin: 5px 0;
    word-break: break-word;
}

.iframeWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.iframeWrapper iframe {
    height: 1px;
    flex-grow: 1;
    width: 100%;
    border-radius: 2px;
    border: 1px solid var(--border-color);
}

.explanation {
    width: 100%;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 4px;
    text-align: left;
}